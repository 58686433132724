<template>
  <div class="sales">
    <v-container>
      <v-row>
        <v-switch
          v-model="showFilters"
          label="Mostrar filtros"
          class="mt-0 mb-3"
          color="secondary"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn class="mx-2" fab right color="secondary" small @click="reload">
          <v-icon> mdi-reload </v-icon>
        </v-btn>
      </v-row>
    </v-container>

    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field
              label="Filtre por cedula o nombre de cliente"
              outlined
              hide-details
              dense
              append-icon="mdi-magnify"
              v-model="searchSaleInput"
              clearable
              clear-icon="mdi-close-circle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-btn block color="secondary" @click="searchSale"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <VueCtkDateTimePicker
              color="orange"
              v-model="datePiker.model"
              :label="datePiker.label"
              :range="true"
              :custom-shortcuts="datePiker.shortcut"
              :format="datePiker.format"
              :formatted="datePiker.formatted"
              inputSize="sm"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="users"
              item-text="name"
              item-value="id"
              label="Seleccione Usuario"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.user"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="offices"
              item-text="name"
              item-value="id"
              label="Seleccione Oficina"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.office"
              @change="changeOffice"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="employees"
              item-text="full_name"
              item-value="id"
              label="Seleccione Asesor"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.employee"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn block color="secondary" @click="searchFilters">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-card class="mb-2" v-if="showSummary">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ sale_summary.total_sales }}
              </v-avatar>
              VENTAS TOTALES
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2 darken-1" color="yellow" text-color="white">
              <v-avatar left class="yellow darken-3">
                {{ sale_summary.total_dresses }}
              </v-avatar>
              VESTIDOS VENDIDOS
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            TOTAL VENTAS <br />
            {{ sale_summary.total_sales_value | formatNumber }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.sale.sales.sales.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.sale.sales.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
        :items-per-page="20"
      >
        <template v-slot:[`item.reference`]="{ item }">
          {{ item.reference ? item.reference : "N/A" }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.substr(0, 10) }}
        </template>
        <template v-slot:[`item.client`]="{ item }">
          {{ item.client.first_name + " " + item.client.last_name }}
        </template>
        <template v-slot:[`item.colombian_id`]="{ item }">
          {{ item.client.colombian_id }}
        </template>
        <template v-slot:[`item.sale_value`]="{ item }">
          {{ item.sale_value | formatNumber }}
        </template>
        <template v-slot:[`item.initial_value`]="{ item }">
          {{ item.initial_value | formatNumber }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            class="ma-2"
            :color="getStatusLoanColor(item.status)"
            text-color="white"
            small
          >
            {{ getStatusLan(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue"
                dark
                v-bind="attrs"
                v-on="on"
                @click="show(item.id)"
                class="px-1"
              >
                mdi-eye-outline
              </v-icon>
            </template>
            <span>VER</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="green"
                dark
                v-bind="attrs"
                v-on="on"
                @click="edit(item.id)"
                class="px-1"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>EDITAR</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text :href="item.invoice_url" target="_blank">
                <v-icon
                  color="green"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="px-1"
                >
                  mdi-cloud-print
                </v-icon>
              </v-btn>
            </template>
            <span>FACTURA</span>
          </v-tooltip>
          <v-tooltip bottom v-if="role == 'SUPERADMIN'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteSale(item.id)"
                class="px-1"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <span>ELIMINAR</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>

    <v-btn
      class="mx-2"
      fab
      bottom
      right
      fixed
      color="secondary"
      href="/sales/create"
    >
      <v-icon> mdi-plus </v-icon>
    </v-btn>

    <!-- Dialog delete sale -->
    <v-dialog v-model="dialogDeleteSale" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Seguro que desea eliminar esta venta?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteSale = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteSaleConfirm"
            >Aceptar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_EMPLOYEES } from "@/store/employee";
import { SET_SALES } from "@/store/sale/sales";
import { SET_USERS } from "@/store/user/users";
import { SET_ALERT } from "@/store/alert";
import { SET_SALE_SUMMARY } from "@/store/summaries";
import { SET_OFFICES } from "@/store/offices";
import { mapGetters } from "vuex";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
export default {
  name: "Users",
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    showFilters: false,
    showSummary: false,
    dialogDeleteSale: false,
    search: "",
    headers: [
      {
        text: "# Contrato",
        align: "start",
        filterable: true,
        value: "reference",
      },
      {
        text: "Fecha",
        align: "start",
        filterable: true,
        value: "created_at",
      },
      {
        text: "Cliente",
        align: "start",
        filterable: true,
        sortable: false,
        value: "client",
      },
      {
        text: "Identificación",
        align: "start",
        filterable: true,
        sortable: true,
        value: "colombian_id",
      },
      {
        text: "Valor de venta",
        align: "start",
        filterable: false,
        sortable: true,
        value: "sale_value",
      },
      {
        text: "Valor Inicial",
        align: "start",
        filterable: false,
        sortable: true,
        value: "initial_value",
      },
      { text: "Estado", value: "status", filterable: false, sortable: false },
      {
        text: "Acciones",
        value: "actions",
        filterable: false,
        sortable: false,
      },
    ],
    pagination: {
      current: 0,
      total: 0,
    },
    datePiker: {
      model: null,
      label: "SELECCIONE EL RANGO DE FECHA *",
      format: "YYYY-MM-DD",
      outputFormat: "YYYY-MM-DD",
      formatted: "ll",
      shortcut: [
        { key: "now", label: "Hoy", value: 0 },
        { key: "thisWeek", label: "Esta semana", value: "isoWeek" },
        { key: "last7Days", label: "Ultimos 7 dias", value: 7 },
        { key: "last30Days", label: "Ultimos 30 dias", value: 30 },
        { key: "thisMonth", label: "Este mes", value: "month" },
        { key: "lastMonth", label: "Ultimo mes", value: "-month" },
        { key: "thisYear", label: "Este año", value: "year" },
      ],
    },
    filters: {
      user: "",
      office: "",
      employee: "",
    },
    searchSaleInput: "",
    searchFiltersText: "",
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
    users: [],
    offices: [],
    employees: [],
    sale_summary: null,
  }),
  created() {
    this.$store.dispatch("getSales");
    this.$store.dispatch("getUsersAll");
    this.$store.dispatch("getOfficesAll");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_SALES) {
        this.pagination.current = state.sale.sales.sales.current_page;
        this.pagination.total = state.sale.sales.sales.last_page;
      }
      if (mutation.type === SET_USERS) {
        this.users = state.user.users.users;
      }
      if (mutation.type === SET_SALE_SUMMARY) {
        this.sale_summary = state.summaries.sale_summary;
        this.showSummary = true;
      }
      if (mutation.type === SET_OFFICES) {
        this.offices = state.offices.offices;
      }
      if (mutation.type === SET_EMPLOYEES) {
        this.employees = state.employee.employees;
      }
    });
  },
  computed: {
    ...mapGetters(["getStatusLan", "getStatusColor", "getStatusLoanColor"]),
    role() {
      return this.$store.state.user.user.role;
    },
  },
  methods: {
    reload() {
      this.searchSaleInput = "";
      this.filters = {
        user: "",
      };
      this.datePiker.model = null;
      this.searchFiltersText = "";
      this.showFilters = false;
      this.showSummary = false;
      this.$store.dispatch("getSales");
    },
    searchSale() {
      if (this.searchSaleInput == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }
      this.showFilters = false;
      this.$store.dispatch("getSales", "search=" + this.searchSaleInput + "&");
    },
    searchFilters() {
      this.searchFiltersText = "";

      if (this.datePiker.model !== null) {
        this.searchFiltersText =
          "min_date=" +
          this.datePiker.model.start +
          "&max_date=" +
          this.datePiker.model.end +
          "&";
      }

      if (this.filters.user !== "") {
        this.searchFiltersText += "user=" + this.filters.user + "&";
      }

      if (this.filters.office !== "") {
        this.searchFiltersText += "office=" + this.filters.office + "&";
      }
      
      if (this.filters.employee !== "") {
        this.searchFiltersText += "employee=" + this.filters.employee + "&";
      }

      if (this.searchFiltersText == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }

      this.showFilters = false;
      this.$store.dispatch("getSales", this.searchFiltersText);
      this.$store.dispatch("getSaleSummary", this.searchFiltersText);
    },
    nextPage() {
      this.$store.dispatch("nextPageSales", this.pagination.current);
    },
    show(id) {
      window.open(`/sales/show/${id}`, '_blank');
    },
    edit(id) {
      window.open(`/sales/edit/${id}`, '_blank');
    },
    deleteSale(id) {
      this.currentDelete = id;
      this.dialogDeleteSale = true;
    },
    deleteSaleConfirm() {
      this.$store.dispatch("deleteSale", this.currentDelete);
      this.dialogDeleteSale = false;
    },
    changeOffice(){
      this.$store.dispatch("getEmployeesAll", "office=" + this.filters.office + "&position=ADVISER");
    }
  },
  components: {
    VueCtkDateTimePicker,
  },
};
</script>
